
#container{
    background-color:#044251;
     /* #EDDFD8; */
    height: 100%;
    /* padding-top: 16px; */
    margin-right: -10px;
    margin-left: -10px;
    margin-top: -10px;
    color: #044251;
    /* color: #4285f4;//#044251 */
    font-size: 32px;
    font-style: oblique;
}
#header{
 display: flex;
    padding-top: 16px;
 background-color: #fff;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 flex-wrap: wrap;
 padding-bottom: 20px;
 /* border-bottom: 2px #fff solid ;044251 */
 border-bottom: 2px #1a8aa3 solid ;
 
}
#overview{
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 2.5%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: oblique;
    color: #fff;
    font-size: 75%;
}
.nav{
    margin-right: 12px;
    text-decoration: underline;
}
#nav{
    font-size: 60%;
    margin-left: 12px;
    align-self: flex-end;
    margin-bottom: 12px;

}
#insta{
    height: 35px;
    width: 35px;
}
#twitter{
    height: 42.5px;
    width: 42.5px;
    /* margin-right: 12px;
    margin-left: 12px; */
}
#fb{
    height: 42.5px;
    width: 42.5px;
    /* margin-right: 12px; */
}
#git{
    height: 50px;
    width: 50px;
    
}
#li{
    height: 42.5px;
    width: 42.5px;
    /* margin-right: 12px; */
}

#mail{
    height: 100%;
    width: 100%;
    /* height: 45.75px;
    width: 45.75px; */
    /* margin-right: 12px; */
    /* margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px; */
    
}
#social{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    
    
}
#footer{
    padding-right: 22%;
    padding-left: 22%;
    /* background-color: #313131; */
    background-color: #000;
    min-height: 280px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -10px;

}
#instaDiv{
    background-color: #fff;
    height: 42.5px;
    width: 42.5px;
    border-radius: 21.25px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 5px;
    align-items: center;
}
#title{
padding-left: 12px;
font-size: 1.5em;
}

#projCard{
    width: 45%;
    padding-top: 5%;
    padding-bottom: 0;
    /* background-color: rgba(183, 176, 172, 0.7); */
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 2.5%;    
    

}

.projImg{
    display: flex;
    height: 150px;
    width: 150px;
    border: #044251 2px dashed;
    border-radius: 75px;
    margin-right: auto;
    margin-left: auto;
}

#cardContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* padding-right: 2.5%;
    padding-left: 2.5%; */
    
}
#projTitle{
    color: #0354b7;
    text-align: center;
    font-size: 80%;
    margin-right: auto;
    margin-left: auto;
}

#top{
    display: flex;
    /* width: 50%; */
    flex-direction: row;
    align-self: center;
    /* justify-content: space-between; */
    align-items: center;
}
.store{
    height: 45px;
    margin: 16px;
    width: 45px;
}
#picContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 50%;
    background-color: rgb(0, 0, 0,0.875);
    /* margin-right: -10px; */
    margin-left: -10px;
    margin-top: -10px;
    margin-bottom: -10px;
}
#profilepic{
    height: 60vh;
    width: 60vh;
    border-radius: 30vh;
}
#acontainer{
    display: flex;
    flex-direction: row;
}
#aboutme{
    display: flex;
    min-height: 100vh;
    width: 50%;
    margin-right: -10px;
    margin-top: -10px;
    margin-bottom: -10px;
    background-color: rgba(0, 0, 255, 0.875);   
}























@media (max-width:480px)  {
    #projCard{
        width: 90%;
        padding-top: 5%;
        /* padding-bottom: 5%; */
        background-color: rgba(183, 176, 172, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        margin: 5%;    
    }
    #picContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50vh;
        background-color: rgb(0, 0, 0,0.875);
        width: 100%;
        margin-left:auto;
        margin-right:auto;
        }
    #profilepic{
        height: 40vh;
        width: 40vh;
        border-radius: 20vh
    }
    #acontainer{
        display: flex;
        flex-direction: column;
        width: 105%;
        margin-right: auto;
        margin-left: auto;
    }
    #aboutme{
        background-color: rgba(0, 0, 255, 0.875);
        min-height: 50vh;
        width: 100%;
        margin-left:auto;
        margin-right:auto;

    }
}